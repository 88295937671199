export default function sendData(dataToBeSent) {
  const sdkIframe = document.getElementById("sdk-iframe");

  var data = {
    type: "applyMakeupByConfig",
    payload: dataToBeSent,
  };
  sdkIframe.contentWindow.postMessage(data, "*");
  console.log(`data to be sent is ${JSON.stringify(dataToBeSent)}`);
}

export function sendDataBySku(skuId) {
  const sdkIframe = document.getElementById("sdk-iframe");

  var data = {
    type: "applyBySku",
    payload: skuId,
  };
  sdkIframe.contentWindow.postMessage(data, "*");
  console.log(`data to be sent is by sku id${JSON.stringify(skuId)}`);
}
export function sendScreenShotMessage() {
  const sdkIframe = document.getElementById("sdk-iframe");

  var payload = {
    type: "screenShot",
  };
  sdkIframe.contentWindow.postMessage(payload, "*");
  console.log("screenshot message sent");
}

export function sendWebDemoMessage() {
  const sdkIframe = document.getElementById("sdk-iframe");

  var payload = {
    type: "webDemo",
  };
  sdkIframe.contentWindow.postMessage(payload, "*");
  console.log("web demo messsage sent");
}

export function SendFrameData() {
  const sdkIframe = document.getElementById("sdk-iframe");

  sdkIframe.contentWindow.postMessage(
    {
      type: "onRecognizationCapture",
      payload: "https://arview.glamarz0.de",
    },
    "*"
  );
}
export function CloseFaceSdk() {
  const sdkIframe = document.getElementById("sdk-iframe");

  sdkIframe.contentWindow.postMessage(
    {
      type: "onRecognizationSDKClose",
      payload: "",
    },
    "*"
  );
}
export function RecognationRetry() {
  const sdkIframe = document.getElementById("sdk-iframe");

  sdkIframe.contentWindow.postMessage(
    {
      type: "onRecognizationRetry",
      payload: "",
    },
    "*"
  );
}
