export const dummyData = [
  {
    skuid: 1,
    categoryName: "glasses",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/glasses/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/glasses/01.gltf",
    ],
    created_at: "2023-10-19T13:40:04.279Z",
    updated_at: "2023-10-19T13:40:04.279Z",
  },
  {
    skuid: 6,
    categoryName: "bracelet",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/bracelet/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/bracelet/01.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 7,
    categoryName: "bracelet",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/bracelet/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/bracelet/02.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 8,
    categoryName: "bracelet",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/bracelet/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/bracelet/03.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 9,
    categoryName: "bracelet",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/bracelet/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/bracelet/04.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 10,
    categoryName: "bracelet",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/bracelet/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/bracelet/05.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 16,
    categoryName: "necklace",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/necklace/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/necklace/01.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 17,
    categoryName: "necklace",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/necklace/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/necklace/02.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 18,
    categoryName: "necklace",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/necklace/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/necklace/03.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 19,
    categoryName: "necklace",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/necklace/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/necklace/04.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 20,
    categoryName: "necklace",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/necklace/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/necklace/05.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 36,
    categoryName: "watch",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/watch/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/watch/01.gltf",
    ],
    created_at: "2023-10-19T13:40:04.279Z",
    updated_at: "2023-10-19T13:40:04.279Z",
  },
  {
    skuid: 37,
    categoryName: "watch",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/watch/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/watch/02.gltf",
    ],
    created_at: "2023-10-19T13:43:23.870Z",
    updated_at: "2023-10-19T13:43:23.870Z",
  },
  {
    skuid: 38,
    categoryName: "watch",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/watch/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/watch/03.gltf",
    ],
    created_at: "2023-10-19T13:43:33.602Z",
    updated_at: "2023-10-19T13:43:33.602Z",
  },
  {
    skuid: 39,
    categoryName: "watch",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/watch/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/watch/04.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 21,
    categoryName: "earring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/earring/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/earring/01.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 22,
    categoryName: "earring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/earring/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/earring/02.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 23,
    categoryName: "earring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/earring/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/earring/03.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 24,
    categoryName: "earring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/earring/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/earring/04.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 26,
    categoryName: "cap",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/cap/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/cap/01.gltf",
    ],
    created_at: "2023-10-19T13:40:04.279Z",
    updated_at: "2023-10-19T13:40:04.279Z",
  },
  {
    skuid: 27,
    categoryName: "cap",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/cap/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/cap/02.gltf",
    ],
    created_at: "2023-10-19T13:43:23.870Z",
    updated_at: "2023-10-19T13:43:23.870Z",
  },
  {
    skuid: 28,
    categoryName: "cap",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/cap/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/cap/03.gltf",
    ],
    created_at: "2023-10-19T13:43:33.602Z",
    updated_at: "2023-10-19T13:43:33.602Z",
  },
  {
    skuid: 29,
    categoryName: "cap",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/cap/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/cap/04.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 30,
    categoryName: "cap",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/cap/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/cap/05.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 40,
    categoryName: "watch",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/watch/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/watch/05.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 43,
    categoryName: "mangtika",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/mangtika/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/mangtika/03.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 25,
    categoryName: "earring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/earring/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/earring/05.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 31,
    categoryName: "ring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/ring/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/ring/01.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 35,
    categoryName: "ring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/ring/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/ring/05.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 34,
    categoryName: "ring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/ring/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/ring/04.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 33,
    categoryName: "ring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/ring/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/ring/03.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 32,
    categoryName: "ring",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/ring/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/ring/02.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 2,
    categoryName: "glasses",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/glasses/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/glasses/02.gltf",
    ],
    created_at: "2023-10-19T13:43:23.870Z",
    updated_at: "2023-10-19T13:43:23.870Z",
  },
  {
    skuid: 3,
    categoryName: "glasses",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/glasses/03.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/glasses/03.gltf",
    ],
    created_at: "2023-10-19T13:43:33.602Z",
    updated_at: "2023-10-19T13:43:33.602Z",
  },
  {
    skuid: 4,
    categoryName: "glasses",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/glasses/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/glasses/04.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 5,
    categoryName: "glasses",
    assetType: "3d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/glasses/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/glasses/05.gltf",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 41,
    categoryName: "mangtika",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/mangtika/01.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/mangtika/01.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 42,
    categoryName: "mangtika",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/mangtika/02.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/mangtika/02.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 44,
    categoryName: "mangtika",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/mangtika/04.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/mangtika/04.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
  {
    skuid: 45,
    categoryName: "mangtika",
    assetType: "2d",
    assetIcon:
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/icons/mangtika/05.jpg",
    assets: [
      "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/2d/mangtika/05.png",
    ],
    created_at: "2023-10-19T13:44:45.316Z",
    updated_at: "2023-10-19T13:44:45.316Z",
  },
];
