import { constant } from "lodash";
import React, { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  setFaceCaptureLoader,
  setFaceCaptureElementState,
  SetSpectsSize,
  setFaceCaptureScreenState,
  setSizeRecievedState,
  setUiSliderState,
} from "../redux/actions/actions";
import SuccessIcon from "./SuccessIcon";
import { CloseFaceSdk, RecognationRetry, SendFrameData } from "./sendData";

const StyledConatiner = styled.div`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.85);
  mask-image: radial-gradient(
    290px 390px,
    transparent ${(props) => (props.show ? "50%" : "0%")},
    rgb(0, 0, 0) ${(props) => (props.show ? "50%" : "0%")}
  );
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FaceContainer = styled.div`
  width: 300px;
  height: 400px;
  /* border: 4px dashed #eba88e; */
  border: ${(props) => `4px dashed ${props.bordercolor}`};
  border-radius: 50%;
  margin: 0 auto;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const DescriptionConatiner = styled.div`
  width: 100%;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;
const StyledText = styled.p`
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
`;

const GifLoader = styled.img`
  width: 100px;
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const Loader = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: ${spin} 2s linear infinite;
`;
const ButtonWrapper = styled.div`
  width: 60px;
  height: 60px;
  background: transparent;
  border: 3px solid white;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 40px;
`;

const StyledButton = styled.button`
  width: 50px;
  height: 50px;
  /* background-color: white; */
  border: none;
  border-radius: 50%;
  background-color: ${(props) => (props.isdisabled ? "darkgrey" : "white")};
  &:hover {
    background-color: lightgrey;
  }
`;

const SizeDisplayWrapper = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SizeDisplay = styled.p`
  color: white;
  font-weight: 800;
`;

const SuccessCheckMark = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;
const CheckIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const FrameSizeSpan = styled.span`
  color: #eba88e;
  font-weight: 800;
  margin-left: 4px;
`;

const RetryBlock = styled.p`
  color: white;
`;

const RetryButton = styled.button`
  color: black;
  background-color: #eba88e;
  font-weight: 600;
  border-radius: 6px;
  font-size: 16px;
  padding: 5px 20px;
`;

const CenterContainer = styled.div`
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: absolute;
`;

const CloseButton = styled.img`
  position: absolute;
  transform: translate(-50%, -50%);
  right: 25px;
  top: 40px;
  border: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const ConditionalText = styled.div`
  height: 50px;
  width: 250px;
  /* background: red; */
  position: absolute;
  top: 20%;
  color: ${(props) => `${props.textcolor}`};
  text-align: center;
  font-weight: 800;
  font-size: 18px;
`;

const SizeCheck = () => {
  const [
    isFaceCaptureLoader,
    isFaceCaptureElements,
    spectsSize,
    isSizeRecieved,
    isFaceRecognationData,
    lensDiameter,
    bridgeWidth,
    distanceRecognition,
  ] = useSelector((state) => [
    state.sdkTabsData.isFaceCaptureLoader,
    state.sdkTabsData.isFaceCaptureElements,
    state.sdkTabsData.spectsSize,
    state.sdkTabsData.isSizeRecieved,
    state.sdkTabsData.isFaceRecognationData,
    state.sdkTabsData.lensDiameter,
    state.sdkTabsData.bridgeWidth,
    state.sdkTabsData.distanceRecognition,
  ]);

  const [showDistanceStatusText, setShowDistanceStatusText] = useState(true);
  const dispatch = useDispatch();

  const handleCaptureButtonClick = () => {
    setShowDistanceStatusText(false);
    setFaceCaptureElementState(false, dispatch);
    setFaceCaptureLoader(true, dispatch);
    // window.FyndStyle.recognizationImageCapture(
    //   "https://a980-14-142-187-98.ngrok-free.app/predict_size"
    // );

    SendFrameData();
  };

  const ConvertFloatTillSecondDecimal = (floatValue) => {
    let roundedValue = floatValue.toFixed(2);
    return roundedValue;
  };

  const handleRetryButtonClick = () => {
    setFaceCaptureScreenState(true, dispatch);
    setFaceCaptureElementState(true, dispatch);
    setSizeRecievedState(false, dispatch);
    RecognationRetry();
  };

  const handleOnCloseButtonClick = () => {
    setFaceCaptureScreenState(false, dispatch);
    setFaceCaptureLoader(false, dispatch);
    setFaceCaptureElementState(true, dispatch);
    setUiSliderState(true, dispatch);
    setSizeRecievedState(false, dispatch);
    CloseFaceSdk();
  };

  const handleDistanceBasedText = () => {
    console.log(226, distanceRecognition);
    switch (distanceRecognition) {
      case "TOO_CLOSE":
        return "Face Too Close";
      case "TOO_FAR":
        return "Face Too Far";
      case "PERFECT":
        return "Perfect!";
      default:
        return "";
    }
  };

  const handleBorderColor = () => {
    console.log(239, distanceRecognition);
    switch (distanceRecognition) {
      case "TOO_CLOSE":
        return "red";
      case "TOO_FAR":
        return "red";
      case "PERFECT":
        return "green";
      default:
        return "";
    }
  };

  const isTryOnDisabled = () => {
    return ["TOO_FAR", "TOO_CLOSE"].includes(distanceRecognition);
  };

  useEffect(() => {
    console.log(232, distanceRecognition);
  }, [distanceRecognition]);

  return (
    <>
      <StyledConatiner show={isFaceCaptureElements}>
        <DescriptionConatiner show={isFaceCaptureElements}>
          <StyledText>
            Keep your face aligned and capture a picture to find your size
          </StyledText>
        </DescriptionConatiner>
        {showDistanceStatusText && (
          <ConditionalText id="msg-div" textcolor={handleBorderColor()}>
            {handleDistanceBasedText()}
          </ConditionalText>
        )}
        <ButtonWrapper show={isFaceCaptureElements}>
          <StyledButton
            disabled={isTryOnDisabled()}
            onClick={handleCaptureButtonClick}
            isdisabled={isTryOnDisabled()}
          />
        </ButtonWrapper>
      </StyledConatiner>

      <CenterContainer>
        <FaceContainer
          show={isFaceCaptureElements}
          bordercolor={handleBorderColor()}
        />
        <GifLoader
          src="https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/assets/website/loading.gif"
          show={isFaceCaptureLoader}
        />

        <SizeDisplayWrapper show={isSizeRecieved}>
          {isFaceRecognationData === true ? (
            <>
              <SuccessIcon />
              <SizeDisplay>
                Your frame size is
                <FrameSizeSpan>{spectsSize}</FrameSizeSpan>
              </SizeDisplay>
              <SizeDisplay>
                Lens diameter is{" "}
                <FrameSizeSpan>
                  {" "}
                  {ConvertFloatTillSecondDecimal(lensDiameter)}{" "}
                </FrameSizeSpan>
                & Bridge width is{" "}
                <FrameSizeSpan>
                  {" "}
                  {ConvertFloatTillSecondDecimal(bridgeWidth)}{" "}
                </FrameSizeSpan>
              </SizeDisplay>
            </>
          ) : (
            <>
              <RetryBlock>
                Error Detecting your face, please try again.
              </RetryBlock>
              <RetryButton onClick={handleRetryButtonClick}>Retry</RetryButton>
            </>
          )}
        </SizeDisplayWrapper>
      </CenterContainer>

      <CloseButton
        onClick={handleOnCloseButtonClick}
        src="https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/assets/website/icons/config_close.svg"
      />
    </>
  );
};

export default SizeCheck;
