import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconButtons from "./IconButton";
import sendData, { sendDataBySku } from "./sendData";
import { sendScreenShotMessage } from "./sendData";
import { useEffect, useState, useRef } from "react";

export default function TryOnIcons(props) {
  let slickSliderRef = useRef();
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [showCameraButton, setshowCameraButton] = useState(true);

  let handleBeforeIconChange = (current) => {
    setshowCameraButton(false);
  };

  let handleTryOnIconChange = (current) => {
    setCurrentIconIndex(current);
    setshowCameraButton(true);
  };

  useEffect(() => {
    if (props.sdk_loaded_bool && props.api_response_bool) {
      // sendData(props.api_data?.[props.stateValue]?.data?.[currentIconIndex]);
      let skuId =
        props.api_data?.[props.stateValue]?.data?.[currentIconIndex].skuid;
      sendDataBySku({ skuId });
    }
  }, [currentIconIndex]);

  function afterTryOnItemStateChange() {
    if (currentIconIndex === 0 && props.api_response_bool) {
      let skuId = props.api_data?.[props.stateValue]?.data?.[0].skuid;
      sendDataBySku({ skuId });

      setshowCameraButton(true);
    } else {
      console.log("Move upper slider");
      slickSliderRef.current.slickGoTo(0, false);
      handleTryOnIconChange(0);
      setCurrentIconIndex(0);
    }
  }

  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 150,
    arrows: false,
    cssEase: "linear",
    swipeToSlide: true,
    touchThreshold: 20,
    beforeChange: handleBeforeIconChange,
    afterChange: handleTryOnIconChange,
  };

  useEffect(() => {
    afterTryOnItemStateChange();
  }, [props.stateValue]);

  return (
    <div id="icon-slider-div">
      <img
        className="icon-center-dash"
        src="./images/rectangle.png"
        alt="rectangle"
      />

      <div className="icon-center-camera-div">
        <div className="icon-center-camera-background">
          <img
            className="icon-center-camera"
            src="./images/camera.png"
            alt="camera "
            onClick={sendScreenShotMessage}
          />
        </div>
      </div>

      <div className="icon-blur-background"></div>
      {console.log(`jjo camera bool is ${showCameraButton}`)}

      <Slider {...settings} ref={slickSliderRef}>
        {props.api_data?.[props.stateValue]?.data?.map((icons, index) => (
          <IconButtons
            currentIndex={currentIconIndex}
            selfIndex={index}
            key={index}
            imgUrl={icons.assetIcon}
            showCameraButton={showCameraButton}
          />
        ))}
      </Slider>
    </div>
  );
}
// }
