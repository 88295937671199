import CameraRectIcons from "./CameraRectIcons";
export default function IconButtons({
  imgUrl,
  currentIndex,
  selfIndex,
  showCameraButton,
}) {
  return (
    <div style={{ position: "relative" }}>
      <img src={imgUrl} className="iconImage" />

      {/* {currentIndex === selfIndex && showCameraButton ? (
        <CameraRectIcons />
      ) : (
        ""
      )} */}

      {/* {1 === "one" ? <CameraRectIcons /> : ""} */}
    </div>
  );
}
