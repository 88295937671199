import TryOnSlider from "./Components/TryOnCarousel";
import "./App.css";
import { useEffect, useState } from "react";
import { dummyData } from "./dummyJsonData";
import { getAllSkuData, setAuthorization } from "./api";
import sendData, {
  sendDataBySku,
  sendWebDemoMessage,
} from "./Components/sendData";
import environment from "./env";

import styled from "styled-components";
import SizeCheck from "./Components/SizeCheck";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/store";

import {
  setFaceCaptureScreenState,
  setUiSliderState,
  setFaceCaptureLoader,
  SetSpectsSize,
  setSizeRecievedState,
  setFaceDataSuccess,
  setLensDiameter,
  setBridgeWidth,
  setDistanceRecognition,
} from "./redux/actions/actions";
const SizeSelectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  background-color: transparent;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const UIWrapper = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  display: ${(props) => (props.show ? "flex" : "none")};
`;

function App() {
  const dispatch = useDispatch();
  const [
    isFaceCaptureScreenState,
    isUiSliderState,
    isFaceCaptureLoader,
    isFaceCaptureElements,
    spectsSize,
    isSizeRecieved,
  ] = useSelector((state) => [
    state.sdkTabsData.isFaceCaptureScreenState,
    state.sdkTabsData.isUiSliderState,
    state.sdkTabsData.isFaceCaptureLoader,
    state.sdkTabsData.isFaceCaptureElements,
    state.sdkTabsData.spectsSize,
    state.sdkTabsData.isSizeRecieved,
  ]);

  let catergoryObject = {};
  let categoryArray = [];
  let api_data;

  const [customData, setcustomData] = useState([]);
  let [gotApiResponse, setGotApiResponse] = useState(false);
  let [isSdkLoaded, setIsSdkLoaded] = useState(false);

  function onRecognationRecieved(payload) {
    console.log("Deepanshu ", payload);
    setFaceCaptureLoader(false, dispatch);

    console.log("success data value is ", payload);
    setFaceDataSuccess(payload.success, dispatch);
    if (payload.success === false) {
      console.log("no face is found");
      setFaceCaptureLoader(false, dispatch);
      setSizeRecievedState(true, dispatch);
    } else {
      SetSpectsSize(payload.frame_size, dispatch);
      setFaceCaptureLoader(false, dispatch);
      setSizeRecievedState(true, dispatch);
      setLensDiameter(payload.dimension.lens_diameter, dispatch);
      setBridgeWidth(payload.dimension.bridge_width, dispatch);
    }
  }

  async function createCustomData() {
    setAuthorization();

    let response = await getAllSkuData();
    if (!response) {
      api_data = dummyData;
    } else {
      api_data = response.data;
    }

    api_data.map((items) => {
      if (catergoryObject.hasOwnProperty(items.categoryName)) {
        catergoryObject[items.categoryName].push(items);
      } else {
        catergoryObject[items.categoryName] = [];
        catergoryObject[items.categoryName].push(items);
      }
    });

    Object.keys(catergoryObject).forEach((key) => {
      const newItem = {};

      newItem.id = key;

      newItem.data = catergoryObject[key];
      categoryArray.push(newItem);
    });

    setcustomData(categoryArray);

    setGotApiResponse(true);
  }

  const onRecognizationDistanceAlert = (payload) => {
    setDistanceRecognition(payload, dispatch);
  };

  useEffect(() => {
    // let isSdkLoaded = false;
    window.addEventListener(
      "message",
      (e) => {
        const {
          data: { type, payload },
          origin,
        } = e;
        console.log("from parent" + type, JSON.stringify(payload));

        switch (type) {
          case "messageToUnity":
            // isSdkLoaded= true;
            setIsSdkLoaded(true);
            console.log(`recieve data message to unity successfull`);
            return;

          case "openRecognizationSDK":
            console.log("123mm");
            setUiSliderState(false, dispatch);
            setFaceCaptureScreenState(true, dispatch);
            return;

          case "onRecognizationDataRecieve":
            console.log("101--");
            console.log(" Face Data recieved payload value is", payload);
            console.log(" Face Data recieved", payload.frame_size);
            onRecognationRecieved(payload);

          case "onRecognizationDistanceAlert":
            console.log("158--", payload);
            onRecognizationDistanceAlert(payload);

          // case "recognizationImageCapture":
          //   console.log(" qq123 payload value is", payload);
          //   return;

          default:
            return;
        }
      },
      false
    );
    createCustomData();

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  useEffect(() => {
    if (gotApiResponse) {
      if (isSdkLoaded) {
        let skuId = customData?.[0].data?.[0].skuid;
        sendDataBySku({ skuId });
        sendWebDemoMessage();
      }
    }
  }, [gotApiResponse, isSdkLoaded]);

  return (
    <>
      <SdkFrame />
      {/* <div className="App"> */}

      <UIWrapper show={isUiSliderState}>
        <TryOnSlider
          api_data={customData}
          is_sdk_loaded={isSdkLoaded}
          got_api_response={gotApiResponse}
        />
      </UIWrapper>

      <SizeSelectionWrapper show={isFaceCaptureScreenState}>
        <SizeCheck />
      </SizeSelectionWrapper>
      {/* </div> */}
    </>
  );
}

function SdkFrame() {
  return (
    <>
      <iframe
        src={environment.sdkUrlOuter}
        id="sdk-iframe"
        allow="camera; navigation"
      ></iframe>
    </>
  );
}

export default App;
